html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input,
select,
button,
textarea {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.d-block {
  display: block !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-table {
  display: table !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-none {
  display: none !important;
}

.d-grid {
  display: grid !important;
}

@media (max-width: 34rem) {
  .p-body {
    margin: 0 1rem;
  }
}
@media (min-width: 34rem) {
  .p-body {
    margin: 0 1.5rem;
  }
}
@media (min-width: 64rem) {
  .p-body {
    width: 56rem;
    margin: 0 auto;
  }
}

.p-container {
  max-width: 928px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
}

/** Shared values across align and justify properties */
.ai-start {
  align-items: flex-start;
}

.ai-end {
  align-items: flex-end;
}

.ai-center {
  align-items: center;
}

.ai-stretch {
  align-items: stretch;
}

.ai-baseline {
  align-items: baseline;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end;
}

.jc-center {
  justify-content: center;
}

.jc-between {
  justify-content: space-between;
}

.jc-around {
  justify-content: space-around;
}

.jc-evenly {
  justify-content: space-evenly;
}

.fd-row {
  flex-direction: row !important;
}

.fd-row-reverse {
  flex-direction: row-reverse !important;
}

.fd-column {
  flex-direction: column !important;
}

.fd-column-reverse {
  flex-direction: column-reverse !important;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-nowrap {
  flex-wrap: nowrap;
}

.m0 {
  margin: 0px;
}

.p0 {
  padding: 0px;
}

.m8 {
  margin: 8px;
}

.p8 {
  padding: 8px;
}

.m16 {
  margin: 16px;
}

.p16 {
  padding: 16px;
}

.m24 {
  margin: 24px;
}

.p24 {
  padding: 24px;
}

.m32 {
  margin: 32px;
}

.p32 {
  padding: 32px;
}

.m40 {
  margin: 40px;
}

.p40 {
  padding: 40px;
}

.m48 {
  margin: 48px;
}

.p48 {
  padding: 48px;
}

.m56 {
  margin: 56px;
}

.p56 {
  padding: 56px;
}

.m64 {
  margin: 64px;
}

.p64 {
  padding: 64px;
}

.m72 {
  margin: 72px;
}

.p72 {
  padding: 72px;
}

.m80 {
  margin: 80px;
}

.p80 {
  padding: 80px;
}

.m88 {
  margin: 88px;
}

.p88 {
  padding: 88px;
}

.m96 {
  margin: 96px;
}

.p96 {
  padding: 96px;
}

.mx0 {
  margin-left: 0px;
  margin-right: 0px;
}

.px0 {
  padding-left: 0px;
  padding-right: 0px;
}

.mx8 {
  margin-left: 8px;
  margin-right: 8px;
}

.px8 {
  padding-left: 8px;
  padding-right: 8px;
}

.mx16 {
  margin-left: 16px;
  margin-right: 16px;
}

.px16 {
  padding-left: 16px;
  padding-right: 16px;
}

.mx24 {
  margin-left: 24px;
  margin-right: 24px;
}

.px24 {
  padding-left: 24px;
  padding-right: 24px;
}

.mx32 {
  margin-left: 32px;
  margin-right: 32px;
}

.px32 {
  padding-left: 32px;
  padding-right: 32px;
}

.mx40 {
  margin-left: 40px;
  margin-right: 40px;
}

.px40 {
  padding-left: 40px;
  padding-right: 40px;
}

.mx48 {
  margin-left: 48px;
  margin-right: 48px;
}

.px48 {
  padding-left: 48px;
  padding-right: 48px;
}

.mx56 {
  margin-left: 56px;
  margin-right: 56px;
}

.px56 {
  padding-left: 56px;
  padding-right: 56px;
}

.mx64 {
  margin-left: 64px;
  margin-right: 64px;
}

.px64 {
  padding-left: 64px;
  padding-right: 64px;
}

.mx72 {
  margin-left: 72px;
  margin-right: 72px;
}

.px72 {
  padding-left: 72px;
  padding-right: 72px;
}

.mx80 {
  margin-left: 80px;
  margin-right: 80px;
}

.px80 {
  padding-left: 80px;
  padding-right: 80px;
}

.mx88 {
  margin-left: 88px;
  margin-right: 88px;
}

.px88 {
  padding-left: 88px;
  padding-right: 88px;
}

.mx96 {
  margin-left: 96px;
  margin-right: 96px;
}

.px96 {
  padding-left: 96px;
  padding-right: 96px;
}

.my0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.py0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.my8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.py8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.my16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.py16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.my24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.py24 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.my32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.py32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.my40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.py40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.my48 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.py48 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.my56 {
  margin-top: 56px;
  margin-bottom: 56px;
}

.py56 {
  padding-top: 56px;
  padding-bottom: 56px;
}

.my64 {
  margin-top: 64px;
  margin-bottom: 64px;
}

.py64 {
  padding-top: 64px;
  padding-bottom: 64px;
}

.my72 {
  margin-top: 72px;
  margin-bottom: 72px;
}

.py72 {
  padding-top: 72px;
  padding-bottom: 72px;
}

.my80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.py80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.my88 {
  margin-top: 88px;
  margin-bottom: 88px;
}

.py88 {
  padding-top: 88px;
  padding-bottom: 88px;
}

.my96 {
  margin-top: 96px;
  margin-bottom: 96px;
}

.py96 {
  padding-top: 96px;
  padding-bottom: 96px;
}

.mt0 {
  margin-top: 0px;
}

.pt0 {
  padding-top: 0px;
}

.mt8 {
  margin-top: 8px;
}

.pt8 {
  padding-top: 8px;
}

.mt16 {
  margin-top: 16px;
}

.pt16 {
  padding-top: 16px;
}

.mt24 {
  margin-top: 24px;
}

.pt24 {
  padding-top: 24px;
}

.mt32 {
  margin-top: 32px;
}

.pt32 {
  padding-top: 32px;
}

.mt40 {
  margin-top: 40px;
}

.pt40 {
  padding-top: 40px;
}

.mt48 {
  margin-top: 48px;
}

.pt48 {
  padding-top: 48px;
}

.mt56 {
  margin-top: 56px;
}

.pt56 {
  padding-top: 56px;
}

.mt64 {
  margin-top: 64px;
}

.pt64 {
  padding-top: 64px;
}

.mt72 {
  margin-top: 72px;
}

.pt72 {
  padding-top: 72px;
}

.mt80 {
  margin-top: 80px;
}

.pt80 {
  padding-top: 80px;
}

.mt88 {
  margin-top: 88px;
}

.pt88 {
  padding-top: 88px;
}

.mt96 {
  margin-top: 96px;
}

.pt96 {
  padding-top: 96px;
}

.mt-auto {
  margin-top: auto;
}

.mb0 {
  margin-bottom: 0px;
}

.pb0 {
  padding-bottom: 0px;
}

.mb8 {
  margin-bottom: 8px;
}

.pb8 {
  padding-bottom: 8px;
}

.mb16 {
  margin-bottom: 16px;
}

.pb16 {
  padding-bottom: 16px;
}

.mb24 {
  margin-bottom: 24px;
}

.pb24 {
  padding-bottom: 24px;
}

.mb32 {
  margin-bottom: 32px;
}

.pb32 {
  padding-bottom: 32px;
}

.mb40 {
  margin-bottom: 40px;
}

.pb40 {
  padding-bottom: 40px;
}

.mb48 {
  margin-bottom: 48px;
}

.pb48 {
  padding-bottom: 48px;
}

.mb56 {
  margin-bottom: 56px;
}

.pb56 {
  padding-bottom: 56px;
}

.mb64 {
  margin-bottom: 64px;
}

.pb64 {
  padding-bottom: 64px;
}

.mb72 {
  margin-bottom: 72px;
}

.pb72 {
  padding-bottom: 72px;
}

.mb80 {
  margin-bottom: 80px;
}

.pb80 {
  padding-bottom: 80px;
}

.mb88 {
  margin-bottom: 88px;
}

.pb88 {
  padding-bottom: 88px;
}

.mb96 {
  margin-bottom: 96px;
}

.pb96 {
  padding-bottom: 96px;
}

.mb-auto {
  margin-bottom: auto;
}

.ml0 {
  margin-left: 0px;
}

.pl0 {
  padding-left: 0px;
}

.ml8 {
  margin-left: 8px;
}

.pl8 {
  padding-left: 8px;
}

.ml16 {
  margin-left: 16px;
}

.pl16 {
  padding-left: 16px;
}

.ml24 {
  margin-left: 24px;
}

.pl24 {
  padding-left: 24px;
}

.ml32 {
  margin-left: 32px;
}

.pl32 {
  padding-left: 32px;
}

.ml40 {
  margin-left: 40px;
}

.pl40 {
  padding-left: 40px;
}

.ml48 {
  margin-left: 48px;
}

.pl48 {
  padding-left: 48px;
}

.ml56 {
  margin-left: 56px;
}

.pl56 {
  padding-left: 56px;
}

.ml64 {
  margin-left: 64px;
}

.pl64 {
  padding-left: 64px;
}

.ml72 {
  margin-left: 72px;
}

.pl72 {
  padding-left: 72px;
}

.ml80 {
  margin-left: 80px;
}

.pl80 {
  padding-left: 80px;
}

.ml88 {
  margin-left: 88px;
}

.pl88 {
  padding-left: 88px;
}

.ml96 {
  margin-left: 96px;
}

.pl96 {
  padding-left: 96px;
}

.ml-auto {
  margin-left: auto;
}

.mr0 {
  margin-right: 0px;
}

.pr0 {
  padding-right: 0px;
}

.mr8 {
  margin-right: 8px;
}

.pr8 {
  padding-right: 8px;
}

.mr16 {
  margin-right: 16px;
}

.pr16 {
  padding-right: 16px;
}

.mr24 {
  margin-right: 24px;
}

.pr24 {
  padding-right: 24px;
}

.mr32 {
  margin-right: 32px;
}

.pr32 {
  padding-right: 32px;
}

.mr40 {
  margin-right: 40px;
}

.pr40 {
  padding-right: 40px;
}

.mr48 {
  margin-right: 48px;
}

.pr48 {
  padding-right: 48px;
}

.mr56 {
  margin-right: 56px;
}

.pr56 {
  padding-right: 56px;
}

.mr64 {
  margin-right: 64px;
}

.pr64 {
  padding-right: 64px;
}

.mr72 {
  margin-right: 72px;
}

.pr72 {
  padding-right: 72px;
}

.mr80 {
  margin-right: 80px;
}

.pr80 {
  padding-right: 80px;
}

.mr88 {
  margin-right: 88px;
}

.pr88 {
  padding-right: 88px;
}

.mr96 {
  margin-right: 96px;
}

.pr96 {
  padding-right: 96px;
}

.mr-auto {
  margin-right: auto;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.gap0 {
  gap: 0px;
}

.c-gap0 {
  column-gap: 0px;
}

.r-gap0 {
  row-gap: 0px;
}

.gap8 {
  gap: 8px;
}

.c-gap8 {
  column-gap: 8px;
}

.r-gap8 {
  row-gap: 8px;
}

.gap16 {
  gap: 16px;
}

.c-gap16 {
  column-gap: 16px;
}

.r-gap16 {
  row-gap: 16px;
}

.gap24 {
  gap: 24px;
}

.c-gap24 {
  column-gap: 24px;
}

.r-gap24 {
  row-gap: 24px;
}

.gap32 {
  gap: 32px;
}

.c-gap32 {
  column-gap: 32px;
}

.r-gap32 {
  row-gap: 32px;
}

.gap40 {
  gap: 40px;
}

.c-gap40 {
  column-gap: 40px;
}

.r-gap40 {
  row-gap: 40px;
}

.gap48 {
  gap: 48px;
}

.c-gap48 {
  column-gap: 48px;
}

.r-gap48 {
  row-gap: 48px;
}

.gap56 {
  gap: 56px;
}

.c-gap56 {
  column-gap: 56px;
}

.r-gap56 {
  row-gap: 56px;
}

.gap64 {
  gap: 64px;
}

.c-gap64 {
  column-gap: 64px;
}

.r-gap64 {
  row-gap: 64px;
}

.gap72 {
  gap: 72px;
}

.c-gap72 {
  column-gap: 72px;
}

.r-gap72 {
  row-gap: 72px;
}

.gap80 {
  gap: 80px;
}

.c-gap80 {
  column-gap: 80px;
}

.r-gap80 {
  row-gap: 80px;
}

.gap88 {
  gap: 88px;
}

.c-gap88 {
  column-gap: 88px;
}

.r-gap88 {
  row-gap: 88px;
}

.gap96 {
  gap: 96px;
}

.c-gap96 {
  column-gap: 96px;
}

.r-gap96 {
  row-gap: 96px;
}

.ws1 {
  width: 60px;
}

.wmn1 {
  min-width: 60px;
}

.wmx1 {
  max-width: 60px;
}

.ws2 {
  width: 136px;
}

.wmn2 {
  min-width: 136px;
}

.wmx2 {
  max-width: 136px;
}

.ws3 {
  width: 212px;
}

.wmn3 {
  min-width: 212px;
}

.wmx3 {
  max-width: 212px;
}

.ws4 {
  width: 288px;
}

.wmn4 {
  min-width: 288px;
}

.wmx4 {
  max-width: 288px;
}

.ws5 {
  width: 364px;
}

.wmn5 {
  min-width: 364px;
}

.wmx5 {
  max-width: 364px;
}

.ws6 {
  width: 440px;
}

.wmn6 {
  min-width: 440px;
}

.wmx6 {
  max-width: 440px;
}

.ws7 {
  width: 516px;
}

.wmn7 {
  min-width: 516px;
}

.wmx7 {
  max-width: 516px;
}

.ws8 {
  width: 592px;
}

.wmn8 {
  min-width: 592px;
}

.wmx8 {
  max-width: 592px;
}

.ws9 {
  width: 668px;
}

.wmn9 {
  min-width: 668px;
}

.wmx9 {
  max-width: 668px;
}

.ws10 {
  width: 744px;
}

.wmn10 {
  min-width: 744px;
}

.wmx10 {
  max-width: 744px;
}

.ws11 {
  width: 820px;
}

.wmn11 {
  min-width: 820px;
}

.wmx11 {
  max-width: 820px;
}

.ws12 {
  width: 896px;
}

.wmn12 {
  min-width: 896px;
}

.wmx12 {
  max-width: 896px;
}

.w-auto {
  width: auto;
}

.w0 {
  width: 0;
}

.w10 {
  width: 10%;
}

.w20 {
  width: 20%;
}

.w30 {
  width: 30%;
}

.w40 {
  width: 40%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}

.w70 {
  width: 70%;
}

.w80 {
  width: 80%;
}

.w90 {
  width: 90%;
}

.w100 {
  width: 100%;
}

.tc-white {
  color: #fff !important;
}

.tc-transparent {
  color: transparent !important;
}

.tc-blue-100 {
  color: #e5f0ff !important;
}

.tc-blue-300 {
  color: #b0cdf3 !important;
}

.tc-blue-500 {
  color: #8bb4ea !important;
}

.tc-blue-700 {
  color: #5f7ba0 !important;
}

.tc-blue-900 {
  color: #2d394a !important;
}

.tc-purple-25 {
  color: #fcfcff !important;
}

.tc-purple-50 {
  color: #f7f7ff !important;
}

.tc-purple-100 {
  color: #ebebff !important;
}

.tc-purple-300 {
  color: #b1b0f5 !important;
}

.tc-purple-500 {
  color: #8e8cee !important;
}

.tc-purple-700 {
  color: #6160a2 !important;
}

.tc-purple-900 {
  color: #2e2e4c !important;
}

.tc-primary-25 {
  color: #fcfcff !important;
}

.tc-primary-50 {
  color: #f7f7ff !important;
}

.tc-primary-100 {
  color: #ebebff !important;
}

.tc-primary-300 {
  color: #b1b0f5 !important;
}

.tc-primary-500 {
  color: #8e8cee !important;
}

.tc-primary-700 {
  color: #6160a2 !important;
}

.tc-primary-900 {
  color: #2e2e4c !important;
}

.tc-red-100 {
  color: #fedede !important;
}

.tc-red-300 {
  color: #faa0a0 !important;
}

.tc-red-500 {
  color: #e55454 !important;
}

.tc-red-700 {
  color: #c64848 !important;
}

.tc-red-900 {
  color: #4b2525 !important;
}

.tc-grey-100 {
  color: #fafaff !important;
}

.tc-grey-200 {
  color: #f5f6fb !important;
}

.tc-grey-300 {
  color: #ededf2 !important;
}

.tc-grey-400 {
  color: #d2d2d8 !important;
}

.tc-grey-500 {
  color: #91919c !important;
}

.tc-grey-600 {
  color: #696970 !important;
}

.tc-grey-700 {
  color: #4c4c53 !important;
}

.tc-grey-900 {
  color: #26262e !important;
}

.tc-green-100 {
  color: #e4ffe6 !important;
}

.tc-green-300 {
  color: #c4f5c8 !important;
}

.tc-green-500 {
  color: #84de8a !important;
}

.tc-green-700 {
  color: #5b985f !important;
}

.tc-green-900 {
  color: #354a2d !important;
}

.tc-glacier-100 {
  color: #e0f7fe !important;
}

.tc-glacier-300 {
  color: #aeddec !important;
}

.tc-glacier-500 {
  color: #8bcbdf !important;
}

.tc-glacier-700 {
  color: #5d8896 !important;
}

.tc-glacier-900 {
  color: #2d4148 !important;
}

.tc-spearmint-100 {
  color: #e3fff2 !important;
}

.tc-spearmint-300 {
  color: #aaeacc !important;
}

.tc-spearmint-500 {
  color: #85dcb4 !important;
}

.tc-spearmint-700 {
  color: #599278 !important;
}

.tc-spearmint-900 {
  color: #2b4639 !important;
}

.tc-pink-100 {
  color: #ffebf1 !important;
}

.tc-pink-300 {
  color: #ffb1cb !important;
}

.tc-pink-500 {
  color: #f96092 !important;
}

.tc-pink-700 {
  color: #c0305f !important;
}

.tc-pink-900 {
  color: #700024 !important;
}

.tc-yellow-100 {
  color: #fff8e3 !important;
}

.tc-yellow-300 {
  color: #fae3a5 !important;
}

.tc-yellow-500 {
  color: #f7ce5c !important;
}

.tc-yellow-700 {
  color: #cc9e21 !important;
}

.tc-yellow-900 {
  color: #4a3d10 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-blue-100 {
  background-color: #e5f0ff !important;
}

.bg-blue-300 {
  background-color: #b0cdf3 !important;
}

.bg-blue-500 {
  background-color: #8bb4ea !important;
}

.bg-blue-700 {
  background-color: #5f7ba0 !important;
}

.bg-blue-900 {
  background-color: #2d394a !important;
}

.bg-purple-25 {
  background-color: #fcfcff !important;
}

.bg-purple-50 {
  background-color: #f7f7ff !important;
}

.bg-purple-100 {
  background-color: #ebebff !important;
}

.bg-purple-300 {
  background-color: #b1b0f5 !important;
}

.bg-purple-500 {
  background-color: #8e8cee !important;
}

.bg-purple-700 {
  background-color: #6160a2 !important;
}

.bg-purple-900 {
  background-color: #2e2e4c !important;
}

.bg-primary-25 {
  background-color: #fcfcff !important;
}

.bg-primary-50 {
  background-color: #f7f7ff !important;
}

.bg-primary-100 {
  background-color: #ebebff !important;
}

.bg-primary-300 {
  background-color: #b1b0f5 !important;
}

.bg-primary-500 {
  background-color: #8e8cee !important;
}

.bg-primary-700 {
  background-color: #6160a2 !important;
}

.bg-primary-900 {
  background-color: #2e2e4c !important;
}

.bg-red-100 {
  background-color: #fedede !important;
}

.bg-red-300 {
  background-color: #faa0a0 !important;
}

.bg-red-500 {
  background-color: #e55454 !important;
}

.bg-red-700 {
  background-color: #c64848 !important;
}

.bg-red-900 {
  background-color: #4b2525 !important;
}

.bg-grey-100 {
  background-color: #fafaff !important;
}

.bg-grey-200 {
  background-color: #f5f6fb !important;
}

.bg-grey-300 {
  background-color: #ededf2 !important;
}

.bg-grey-400 {
  background-color: #d2d2d8 !important;
}

.bg-grey-500 {
  background-color: #91919c !important;
}

.bg-grey-600 {
  background-color: #696970 !important;
}

.bg-grey-700 {
  background-color: #4c4c53 !important;
}

.bg-grey-900 {
  background-color: #26262e !important;
}

.bg-green-100 {
  background-color: #e4ffe6 !important;
}

.bg-green-300 {
  background-color: #c4f5c8 !important;
}

.bg-green-500 {
  background-color: #84de8a !important;
}

.bg-green-700 {
  background-color: #5b985f !important;
}

.bg-green-900 {
  background-color: #354a2d !important;
}

.bg-glacier-100 {
  background-color: #e0f7fe !important;
}

.bg-glacier-300 {
  background-color: #aeddec !important;
}

.bg-glacier-500 {
  background-color: #8bcbdf !important;
}

.bg-glacier-700 {
  background-color: #5d8896 !important;
}

.bg-glacier-900 {
  background-color: #2d4148 !important;
}

.bg-spearmint-100 {
  background-color: #e3fff2 !important;
}

.bg-spearmint-300 {
  background-color: #aaeacc !important;
}

.bg-spearmint-500 {
  background-color: #85dcb4 !important;
}

.bg-spearmint-700 {
  background-color: #599278 !important;
}

.bg-spearmint-900 {
  background-color: #2b4639 !important;
}

.bg-pink-100 {
  background-color: #ffebf1 !important;
}

.bg-pink-300 {
  background-color: #ffb1cb !important;
}

.bg-pink-500 {
  background-color: #f96092 !important;
}

.bg-pink-700 {
  background-color: #c0305f !important;
}

.bg-pink-900 {
  background-color: #700024 !important;
}

.bg-yellow-100 {
  background-color: #fff8e3 !important;
}

.bg-yellow-300 {
  background-color: #fae3a5 !important;
}

.bg-yellow-500 {
  background-color: #f7ce5c !important;
}

.bg-yellow-700 {
  background-color: #cc9e21 !important;
}

.bg-yellow-900 {
  background-color: #4a3d10 !important;
}

:root {
  --ds-white: #fff;
  --ds-transparent: transparent;
  --ds-blue-100: #e5f0ff;
  --ds-blue-300: #b0cdf3;
  --ds-blue-500: #8bb4ea;
  --ds-blue-700: #5f7ba0;
  --ds-blue-900: #2d394a;
  --ds-purple-25: #fcfcff;
  --ds-purple-50: #f7f7ff;
  --ds-purple-100: #ebebff;
  --ds-purple-300: #b1b0f5;
  --ds-purple-500: #8e8cee;
  --ds-purple-700: #6160a2;
  --ds-purple-900: #2e2e4c;
  --ds-primary-25: #fcfcff;
  --ds-primary-50: #f7f7ff;
  --ds-primary-100: #ebebff;
  --ds-primary-300: #b1b0f5;
  --ds-primary-500: #8e8cee;
  --ds-primary-700: #6160a2;
  --ds-primary-900: #2e2e4c;
  --ds-red-100: #fedede;
  --ds-red-300: #faa0a0;
  --ds-red-500: #e55454;
  --ds-red-700: #c64848;
  --ds-red-900: #4b2525;
  --ds-grey-100: #fafaff;
  --ds-grey-200: #f5f6fb;
  --ds-grey-300: #ededf2;
  --ds-grey-400: #d2d2d8;
  --ds-grey-500: #91919c;
  --ds-grey-600: #696970;
  --ds-grey-700: #4c4c53;
  --ds-grey-900: #26262e;
  --ds-green-100: #e4ffe6;
  --ds-green-300: #c4f5c8;
  --ds-green-500: #84de8a;
  --ds-green-700: #5b985f;
  --ds-green-900: #354a2d;
  --ds-glacier-100: #e0f7fe;
  --ds-glacier-300: #aeddec;
  --ds-glacier-500: #8bcbdf;
  --ds-glacier-700: #5d8896;
  --ds-glacier-900: #2d4148;
  --ds-spearmint-100: #e3fff2;
  --ds-spearmint-300: #aaeacc;
  --ds-spearmint-500: #85dcb4;
  --ds-spearmint-700: #599278;
  --ds-spearmint-900: #2b4639;
  --ds-pink-100: #ffebf1;
  --ds-pink-300: #ffb1cb;
  --ds-pink-500: #f96092;
  --ds-pink-700: #c0305f;
  --ds-pink-900: #700024;
  --ds-yellow-100: #fff8e3;
  --ds-yellow-300: #fae3a5;
  --ds-yellow-500: #f7ce5c;
  --ds-yellow-700: #cc9e21;
  --ds-yellow-900: #4a3d10;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/merriweather/v30/u-4n0qyriQwlOrhSvowK_l52xwNZVcf6hPvhPUWH.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/merriweather/v30/u-4n0qyriQwlOrhSvowK_l52xwNZXMf6hPvhPUWH.woff2) format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/merriweather/v30/u-4n0qyriQwlOrhSvowK_l52xwNZV8f6hPvhPUWH.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/merriweather/v30/u-4n0qyriQwlOrhSvowK_l52xwNZVsf6hPvhPUWH.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/merriweather/v30/u-4n0qyriQwlOrhSvowK_l52xwNZWMf6hPvhPQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
body {
  font-family: "Lato", sans-serif;
}

.p--serif {
  font-family: "Merriweather", serif;
}

.p-h1, .p-h1--xl,
.p-h2,
.p-h3,
.p-h4,
.p-p,
.p-p--small {
  color: #26262e;
}

.p-h1, .p-h1--xl {
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.5px;
}
@media (max-width: 34rem) {
  .p-h1, .p-h1--xl {
    font-size: 24px;
    line-height: 34px;
  }
}
.p-h1--xl {
  font-size: 48px;
  line-height: 58px;
}
@media (max-width: 34rem) {
  .p-h1--xl {
    font-size: 32px;
    line-height: 42px;
  }
}

.p-h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.5px;
}
@media (max-width: 34rem) {
  .p-h2 {
    font-size: 18px;
    line-height: 24px;
  }
}

.p-h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
@media (max-width: 34rem) {
  .p-h3 {
    font-size: 16px;
    line-height: 24px;
  }
}

.p-h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.p-p, .p-p--small {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.p-p--small {
  font-size: 12px;
  line-height: 18px;
}

.p-error {
  color: #e55454;
}

.p-a {
  color: #8e8cee;
  text-decoration: none;
}
.p-a:hover, .p-a:focus {
  color: #6160a2;
}
.p-a:focus-visible {
  outline: 2px solid #26262e;
  border-radius: 2px;
  outline-offset: 2px;
}

.ta-left {
  text-align: left;
}

.ta-center {
  text-align: center;
}

.ta-right {
  text-align: right;
}

.ta-justify {
  text-align: justify;
}

.fs-italic {
  font-style: italic;
}

.fs-normal {
  font-style: normal;
}

.c-auto {
  cursor: auto;
}

.c-default {
  cursor: default;
}

.c-pointer {
  cursor: pointer;
}

.c-text {
  cursor: text;
}

.c-wait {
  cursor: wait;
}

.c-move {
  cursor: move;
}

.c-not-allowed {
  cursor: not-allowed;
}

.c-help {
  cursor: help;
}

.br0 {
  border-radius: 0px;
}

.br2 {
  border-radius: 2px;
}

.br4 {
  border-radius: 4px;
}

.br8 {
  border-radius: 8px;
}

.br12 {
  border-radius: 12px;
}

.br16 {
  border-radius: 16px;
}

.br24 {
  border-radius: 24px;
}

.br-circle {
  border-radius: 50%;
}

.br-pill {
  border-radius: 999px;
}

@keyframes spinner-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.p-btn, .p-btn--outline-grey, .p-btn--outline-white, .p-btn--outline, .p-btn--success, .p-btn--danger, .p-btn--secondary, .p-btn--secondary-inverted, .p-btn--secondary-white, .p-btn--secondary-grey, .p-btn--primary {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  height: 48px;
  padding: 0 24px;
  line-height: 24px;
  font-size: 16px;
  font-family: inherit;
  letter-spacing: 0.5px;
  text-decoration: none;
  text-align: center;
  font-weight: 700;
  border-radius: 8px;
  border: none;
  transition: 0.3s opacity, 0.3s background-color;
}
.p-btn[disabled], [disabled].p-btn--outline-grey, [disabled].p-btn--outline-white, [disabled].p-btn--outline, [disabled].p-btn--success, [disabled].p-btn--danger, [disabled].p-btn--secondary, [disabled].p-btn--secondary-inverted, [disabled].p-btn--secondary-white, [disabled].p-btn--secondary-grey, [disabled].p-btn--primary {
  cursor: default;
  opacity: 0.5;
  cursor: not-allowed;
}
.p-btn--icon-only {
  width: 48px;
  padding: 0;
}
.p-btn--primary {
  background-color: #8e8cee;
  color: white;
}
.p-btn--primary:hover {
  background-color: #6160a2;
}
@media (max-width: 34rem) {
  .p-btn--primary:hover {
    background-color: #8e8cee;
  }
}
.p-btn--primary[disabled] {
  background-color: #b1b0f5;
  opacity: 1;
}
.p-btn--primary:focus {
  outline: none;
  box-shadow: 0 0 0 1px white, 0 0 0 3px rgba(142, 140, 238, 0.5);
}
.p-btn--secondary, .p-btn--secondary-inverted, .p-btn--secondary-white, .p-btn--secondary-grey {
  background-color: transparent;
  color: #8e8cee;
}
.p-btn--secondary:hover, .p-btn--secondary-inverted:hover, .p-btn--secondary-white:hover, .p-btn--secondary-grey:hover {
  background-color: #ebebff;
}
@media (max-width: 34rem) {
  .p-btn--secondary:hover, .p-btn--secondary-inverted:hover, .p-btn--secondary-white:hover, .p-btn--secondary-grey:hover {
    background-color: transparent;
  }
}
.p-btn--secondary[disabled], [disabled].p-btn--secondary-inverted, [disabled].p-btn--secondary-white, [disabled].p-btn--secondary-grey {
  color: #6160a2;
}
.p-btn--secondary[disabled]:hover, [disabled].p-btn--secondary-inverted:hover, [disabled].p-btn--secondary-white:hover, [disabled].p-btn--secondary-grey:hover {
  background-color: transparent;
}
.p-btn--secondary:focus, .p-btn--secondary-inverted:focus, .p-btn--secondary-white:focus, .p-btn--secondary-grey:focus {
  outline: none;
  box-shadow: 0 0 0 1px white, 0 0 0 3px rgba(142, 140, 238, 0.5);
}
.p-btn--secondary-grey {
  background-color: #f7f7ff;
}
.p-btn--secondary-white {
  background-color: #fff;
}
.p-btn--secondary-inverted {
  color: #fff;
}
.p-btn--secondary-inverted:hover, .p-btn--secondary-inverted:focus {
  background-color: #6160a2;
}
.p-btn--danger {
  background-color: #e55454;
  color: white;
}
.p-btn--danger:hover {
  background-color: #c64848;
}
@media (max-width: 34rem) {
  .p-btn--danger:hover {
    background-color: #e55454;
  }
}
.p-btn--danger[disabled] {
  background-color: #faa0a0;
  opacity: 1;
}
.p-btn--danger:focus {
  outline: none;
  box-shadow: 0 0 0 1px white, 0 0 0 3px rgba(142, 140, 238, 0.5);
}
.p-btn--success {
  background-color: #84de8a;
  color: white;
}
.p-btn--success:hover {
  background-color: #5b985f;
}
@media (max-width: 34rem) {
  .p-btn--success:hover {
    background-color: #84de8a;
  }
}
.p-btn--success[disabled] {
  background-color: #c4f5c8;
  opacity: 1;
}
.p-btn--success:focus {
  outline: none;
  box-shadow: 0 0 0 1px white, 0 0 0 3px rgba(142, 140, 238, 0.5);
}
.p-btn--outline {
  color: #8e8cee;
  background-color: transparent;
  border: 1px solid #b1b0f5;
}
.p-btn--outline:hover {
  color: #6160a2;
  border-color: #8e8cee;
}
.p-btn--outline[disabled] {
  color: #8e8cee;
  border-color: #b1b0f5;
  opacity: 0.5;
}
.p-btn--outline:focus {
  color: #6160a2;
  outline: none;
  box-shadow: 0 0 0 1px white, 0 0 0 3px rgba(142, 140, 238, 0.5);
}
.p-btn--outline-white {
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
}
.p-btn--outline-white:hover {
  background-color: #6160a2;
  border-color: #6160a2;
}
.p-btn--outline-white[disabled] {
  background-color: transparent;
  opacity: 0.5;
}
.p-btn--outline-white:focus {
  outline: none;
  box-shadow: 0 0 0 1px white, 0 0 0 3px rgba(142, 140, 238, 0.5);
}
.p-btn--outline-grey {
  color: #91919c;
  background-color: transparent;
  border: 1px solid #d2d2d8;
}
.p-btn--outline-grey:hover {
  color: #696970;
  border-color: #91919c;
}
.p-btn--outline-grey[disabled] {
  color: #91919c;
  border-color: #d2d2d8;
  opacity: 0.5;
}
.p-btn--outline-grey:focus {
  color: #696970;
  border-color: #696970;
}
.p-btn--loading {
  color: transparent;
  cursor: default;
  opacity: 1 !important;
}
.p-btn--loading::before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  left: calc(50% - 13px);
  top: calc(50% - 13px);
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-left-color: white;
  animation: spinner-rotate 0.9s infinite;
}
.p-btn--loading.p-btn--secondary::before, .p-btn--loading.p-btn--secondary-inverted::before, .p-btn--loading.p-btn--secondary-grey::before, .p-btn--loading.p-btn--secondary-white::before {
  border-color: rgba(142, 140, 238, 0.5);
  border-left-color: #8e8cee;
}

.sr-only {
  border-width: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.p-input, .p-input--error {
  appearance: none;
  display: block;
  padding-left: 16px;
  font-size: 16px;
  line-height: 24px;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d2d2d8;
  background-color: white;
  color: #26262e;
  transition: box-shadow 0.3s;
}
.p-input::placeholder, .p-input--error::placeholder {
  color: #91919c;
}
.p-input:disabled, .p-input--error:disabled {
  background-color: #ededf2;
  border: none;
}
.p-input:focus, .p-input--error:focus {
  outline: none;
  box-shadow: 0 0 0 2px #8e8cee;
}
.p-input--error {
  border: 1px solid #e55454;
}
.p-input--error:focus {
  outline: none;
  box-shadow: 0 0 0 2px #e55454;
}
.p-input input, .p-input--error input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  appearance: none;
}

.p-radio,
.p-checkbox {
  cursor: pointer;
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

.p-radio + label::before {
  content: "";
  cursor: pointer;
  display: inline-flex;
  margin-right: 16px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border: 1px solid #d2d2d8;
  border-radius: 50%;
  background-color: white;
  transition: 0.3s background-color;
}
.p-radio.p-radio--centered + label::before {
  margin-right: 0;
}

.p-radio--no-icon + label::before {
  display: none !important;
}

.p-radio:checked + label::before {
  border-color: #8e8cee;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 20 20" stroke="white" stroke-width="4" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="50%" fill="%238e8cee"/></svg>');
}

.p-radio:disabled + .p-label {
  cursor: not-allowed;
  opacity: 0.6;
}
.p-radio:disabled + .p-label--bordered:hover {
  border-color: #d2d2d8;
}

.p-checkbox + label::before {
  content: "";
  cursor: pointer;
  display: inline-flex;
  margin-right: 16px;
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border: 1px solid #d2d2d8;
  border-radius: 4px;
  background-color: white;
  transition: 0.3s background-color;
}

.p-checkbox:focus + label::before,
.p-radio:focus + label::before {
  outline: none;
  box-shadow: 0 0 0 2px rgba(142, 140, 238, 0.5);
}

.p-checkbox:checked + label::before {
  background-image: url("./assets/checkmark.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #8e8cee;
  border-color: #8e8cee;
}

.p-checkbox--no-icon + label::before {
  display: none !important;
}

.p-label {
  cursor: pointer;
  display: flex;
  align-items: baseline;
  min-height: 48px;
  line-height: 24px;
  color: #26262e;
}
.p-label--bordered {
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #d2d2d8;
  background-color: white;
  transition: 0.3s border-color, 0.3s background-color;
}
.p-label--bordered:hover {
  border-color: #8e8cee;
}

input:checked[type=radio] + .p-label--bordered,
input:checked[type=checkbox] + .p-label--bordered {
  border-color: #8e8cee;
  background-color: #ebebff;
}

.p-label-container {
  position: relative;
}
.p-label-container input {
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
  left: 16px;
}

.p-select {
  cursor: pointer;
  appearance: none;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #d2d2d8;
  background-color: white;
  font-family: inherit;
  font-size: 16px;
  line-height: 24px;
  color: #26262e;
  padding-left: 16px;
  padding-right: 32px;
  background-image: url("./assets/icon-form-dropdown.svg");
  background-repeat: no-repeat;
  background-position: right center;
  transition: box-shadow 0.3s;
}
.p-select:hover {
  color: #4c4c53 !important;
  border-color: #8e8cee;
}
.p-select:invalid {
  color: #91919c;
}
.p-select:focus {
  outline: none;
  box-shadow: 0 0 0 2px #8e8cee;
}
.p-select option {
  font-family: Arial, Helvetica, sans-serif;
}

.p-notice, .p-notice--info, .p-notice--success, .p-notice--warning, .p-notice--danger, .p-notice--primary {
  padding: 8px 16px;
  border: 1px solid;
  border-radius: 8px;
}
.p-notice--primary {
  background-color: #ebebff;
  border-color: #8e8cee;
  color: #26262e;
}
.p-notice--danger {
  background-color: #fedede;
  border-color: #e55454;
  color: #26262e;
}
.p-notice--warning {
  background-color: #fff8e3;
  border-color: #f7ce5c;
}
.p-notice--success {
  background-color: #e4ffe6;
  border-color: #84de8a;
}
.p-notice--info {
  background-color: #e5f0ff;
  border-color: #8bb4ea;
}

.p-badge, .p-badge--default, .p-badge--info, .p-badge--danger, .p-badge--inactive, .p-badge--pending, .p-badge--active {
  display: inline-block;
  padding: 0 16px;
  font-size: 12px;
  color: #26262e;
  text-align: center;
  height: 18px;
  line-height: 18px;
  border-radius: 9px;
}
.p-badge--active {
  background-color: #c4f5c8;
}
.p-badge--pending {
  background-color: #fae3a5;
}
.p-badge--inactive {
  background-color: #d2d2d8;
}
.p-badge--danger {
  background-color: #faa0a0;
}
.p-badge--info {
  background-color: #b0cdf3;
}
.p-badge--default {
  background-color: #ebebff;
}

.ds-spinner {
  position: relative;
  width: 16px;
  height: 16px;
}
.ds-spinner::before, .ds-spinner::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid #8e8cee;
  border-radius: 16px;
}
.ds-spinner::before {
  opacity: 0.5;
  transform: rotate(90deg);
}
.ds-spinner::after {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  animation: spinner-rotate 0.9s infinite cubic-bezier(0.5, 0.1, 0.5, 0.9);
}
.ds-spinner.ds-spinner__l {
  width: 48px;
  height: 48px;
}
.ds-spinner.ds-spinner__l::before, .ds-spinner.ds-spinner__l::after {
  border-radius: 48px;
  border-width: 6px;
}
.ds-spinner.ds-spinner__m {
  width: 32px;
  height: 32px;
}
.ds-spinner.ds-spinner__m::before, .ds-spinner.ds-spinner__m::after {
  border-radius: 32px;
  border-width: 4px;
}

@keyframes spinner-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ds-card, .ds-card--muted, .ds-card--actionable {
  background-color: white;
  border-radius: 8px;
  border: 2px solid transparent;
  box-shadow: 0px 2px 28px rgba(97, 96, 162, 0.1);
  transition: border-color 0.3s;
}
.ds-card--actionable {
  cursor: pointer;
}
.ds-card--actionable:hover {
  border: 2px solid #b1b0f5;
}
.ds-card--actionable:focus {
  outline: 3px solid #8e8cee;
  border: 2px solid transparent;
}
.ds-card--muted {
  opacity: 0.25;
}
.ds-card--no-dropshadow {
  box-shadow: none;
}

.bs-xs {
  box-shadow: 0px 4px 6px rgba(97, 96, 162, 0.01);
}

.bs-sm {
  box-shadow: 0px 2px 20px 0px rgba(97, 96, 162, 0.06);
}

.bs-md {
  box-shadow: 0px 2px 28px rgba(97, 96, 162, 0.12);
}

.bs-lg {
  box-shadow: 0px 2px 28px rgba(97, 96, 162, 0.15);
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.pac-item {
  display: flex;
  align-items: center;
  height: 48px;
  cursor: pointer;
  border-top: none;
  font-family: Lato, sans-serif;
}
.pac-item:hover {
  background-color: var(--ds-grey-200);
}

.pac-item-selected {
  background-color: var(--ds-primary-100);
}

.pac-item {
  padding-left: 16px;
}

.pac-matched,
.pac-item-query,
.pac-item {
  font-size: 16px;
}

.pac-icon {
  display: none;
}

@keyframes appear-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
  }
}
.pac-container {
  background-color: white;
  border: 1px solid var(--ds-grey-400);
  box-shadow: none;
  border-radius: 8px;
  margin-top: 8px;
  animation-name: appear-in;
  animation-duration: 0.3s;
  animation-fill-mode: both;
}
.pac-container::after {
  content: "";
  display: none;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  background-color: #f5f6fb;
}